import React from 'react';
import './style.less';

interface ModalProps {
  show: boolean;
  onClose: () => void;
  text: string;
}

const Modal: React.FC<ModalProps> = ({ show, onClose, text }) => {
  const modalStyles: React.CSSProperties = {
    display: show ? 'block' : 'none'
  };

  return (
    <div className="modal" style={modalStyles}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Modal;
