import React, { useState, useEffect } from 'react';
import Modal from './components/Modal';
import './index.less';

interface Todo {
  id: number;
  text: string;
  done: boolean;
}

const App: React.FC = () => {
  const [todos, setTodos] = useState<Todo[]>([]);
  const [newTodoText, setNewTodoText] = useState<string>('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>('');

  useEffect(() => {
    fetch('/api/todos')
      .then((res) => res.json())
      .then((data) => setTodos(data))
      .catch((err) => console.error(err));
  }, []);

  const handleNewTodoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTodoText(e.target.value);
  };

  const handleNewTodoSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    fetch('/api/todos', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ text: newTodoText, done: false }),
    })
      .then((res) => res.json())
      .then((data) => {
        setTodos([...todos, data]);
        setNewTodoText('');
      })
      .catch((err) => console.error(err));
  };

  const handleTodoUpdate = (todo: Todo) => {
    fetch(`/api/todos/${todo.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(todo),
    })
      .then((res) => {
        if (res.ok) {
          const updatedTodos = todos.map((t) => (t.id === todo.id ? todo : t));
          setTodos(updatedTodos);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleTodoDelete = (id: number) => {
    fetch(`/api/todos/${id}`, {
      method: 'DELETE',
    })
      .then((res) => {
        if (res.ok) {
          const updatedTodos = todos.filter((t) => t.id !== id);
          setTodos(updatedTodos);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleTodoClick = (todo: Todo) => {
    setModalText(todo.text);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <div className="App">
      <h1>Todos</h1>

      <div className="form-container">
        <form onSubmit={handleNewTodoSubmit}>
          <div className="input-group">
            <input
              type="text"
              id="newTodo"
              value={newTodoText}
              onChange={handleNewTodoChange}
              placeholder="Please input"
            />
            <button type="submit">Add</button>
          </div>
        </form>
      </div>

      <ul className="todo-list">
        {todos.map((todo) => (
          <li key={todo.id}>
            <input
              type="checkbox"
              className="todo-checkbox"
              checked={todo.done}
              onChange={() => handleTodoUpdate({ ...todo, done: !todo.done })}
            />
            <span
              className={`todo-text ${todo.done ? 'done' : ''}`}
              onClick={() => handleTodoClick(todo)}
            >
              {todo.text}
            </span>
            <button
              className="todo-delete-btn"
              onClick={() => handleTodoDelete(todo.id)}
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
      <Modal show={modalOpen} onClose={handleModalClose} text={modalText} />
    </div>
  );
};

export default App;
